<template>

  <div class='wameed-dashboard-page-content'>
    <page-header
      :title="$t('settings.policy.title')"
      :paths="[{title:$t('settings.title'),link:'settings'}]"

    />

    <b-card no-body class='wameed-card'>
      <b-card-header class='align-items-start tab-title'>
        <h5 class='text-reg-18 text-font-main'>
          {{ $t('settings.policy.title') }}
        </h5>
      </b-card-header>
      <wameed-form refs='' @onSubmit='submit'>
        <div slot-scope='{ invalid }'>
          <b-card-body
            class='position-relative py-5 justify-content-between px-5'
          >
            <h5 class='text-reg-14 text-font-main'>
              {{ $t('settings.content.ar') }}
            </h5>
            <vue-editor
              v-model='contentAr' :editorToolbar='customToolbar'
            />
            <hr class='my-5'>
            <h5 class='text-reg-14 text-font-main'>
              {{ $t('settings.content.en') }}
            </h5>
            <vue-editor
              v-model='contentEn' :editorToolbar='customToolbar'
            />

          </b-card-body>
          <b-card-footer class='px-4 py-3'>
            <div class='d-flex flex-wrap justify-content-between'>
              <div class='px-4 py-3'>
                <wameed-btn
                  classes='  text-medium-16 text-white rounded-10'
                  :title="$t('common.save_edited')"
                  :disabled='invalid'
                  type='submit'
                  variant='main'
                />
              </div>
              <div class='px-4 py-3'>
                <wameed-btn
                  classes='  text-medium-16 text-font-secondary rounded-10 '
                  :title="$t('common.cancel')"
                  type='button'
                  variant='gray'
                  @onClick='goBack'
                />
              </div>
            </div>
          </b-card-footer>
        </div>
      </wameed-form>
    </b-card>
  </div>
</template>

<script>
import PageHeader from '@/components/wameed/WameedPageHeader.vue';


import { WameedBtn, WameedForm, wameedNotify } from 'wameed-ui/dist/wameed-ui.esm';
import { VueEditor } from 'vue2-editor';
import i18n from '@/libs/i18n';

export default {
  components: {
    PageHeader, VueEditor
    ,
    WameedBtn,
    WameedForm
  },
  data() {
    return {
      contentAr: '',
      contentEn: '',
      customToolbar: [[{
        header: [false, 1, 2, 3, 4, 5, 6]
      }], ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{
          align: ''
        }, {
          align: 'center'
        }, {
          align: 'right'
        }, {
          align: 'justify'
        }], ['blockquote', 'code-block'], [{
          list: 'ordered'
        }, {
          list: 'bullet'
        }, {
          list: 'check'
        }], [{
          indent: '-1'
        }, {
          indent: '+1'
        }], // outdent/indent
        // dropdown with defaults from theme
        ['clean'] // remove formatting button
      ]
    };
  },
  methods: {

    goBack() {
      this.$router.push({ name: 'settings' });
    },
    submit() {

      if(!this.contentAr || !this.contentEn) {

        wameedNotify({ title: i18n.t('error_messages.'), type: 'fail' });

      }
//get the page name from route meta
      const page = this.$route.meta.page;
      const data = {
        page: page,
        content_ar: this.contentAr,
        content_en: this.contentEn
      };
      this.$store.dispatch('admin/settings/pages/update', data);
    }

  },

  async created() {
    await this.$store.dispatch('admin/settings/pages/get', this.$route.meta.page);
    this.contentAr = this.$store.state.admin.settings.pages.data.content_ar;
    this.contentEn = this.$store.state.admin.settings.pages.data.content_en;
  }
};
</script>
<style>
span.ql-picker-label {
  padding: 0 0 0 20px !important;
}
</style>
